<template>
  <b-row>
    <b-col lg="6">
      <MainForm
        v-on:submit="submit" />
    </b-col>
  </b-row>
</template>
<script>
import MainForm from './form'

export default {
  name: 'userCreate',
  created() {},
  methods: {
    submit() {
      const vm = this
      vm.$store.dispatch('user/STORE').then((res) => {
        vm.$notification.success({
          message: 'New User Created',
          description: 'Successfully created.',
        })
        vm.$router.push({ path: '/users' })
      }).catch((res) => {
        // console.log('error response ', res.data)
        res.data.forEach(error => {
          vm.$notification.error({
            message: 'Error',
            description: error.message,
          })
        })
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
